import React from 'react';
import { Button } from 'shamrock-clover-ui';
import css from './styles/company-plan.scss';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  plan: any;
  hasProTransLite: boolean;
  requestUpgradeClick: Function;
}

const CompanyPlan: React.FC<Props> = ({ plan, hasProTransLite, requestUpgradeClick }: Props) => {
  const currentPlanCriteria =
    (hasProTransLite && plan.name === 'Premium') || (!hasProTransLite && plan.name === 'Basic');

  const { t, i18n } = useTranslation();
  return (
    <div className={css.planContainer} style={{ height: i18n.language === 'en' ? '475px' : '526px' }}>
      <div className={css.title}>
        ProTransport <span className={css[plan.name.toLowerCase()]}>{t(plan.name).toUpperCase()}</span>
      </div>
      <div className={css.description} style={{ height: i18n.language === 'en' ? '290px' : '365px' }}>
        <ul>
          {plan.features.map((feature) =>
            feature.showBullet ? (
              <li className={css.bulletPoint} key={feature.feature}>
                <span className={css.item}>{t(feature.feature)}</span>
              </li>
            ) : (
              <div className={css.noBulletPoint} key={feature.feature}>
                <span className={css.item}>{t(feature.feature)}</span>
              </div>
            ),
          )}
        </ul>
      </div>
      {currentPlanCriteria ? (
        <div>
          <div className={css.yourPlanContainer}>
            <div className={css.icon}>
              <CheckIcon htmlColor="white" />
            </div>
            <div>{t('your_current_plan')}</div>
          </div>
          <div className={css.shadowWrapper}>
            <div className={css.triangleLeft} />
            <div className={css.shadow} />
            <div className={css.triangleRight} />
          </div>
        </div>
      ) : (
        <div className={css.requestUpgradeButton}>
          <Button width="180px" height="40px" onClick={() => requestUpgradeClick()}>
            {t('request_upgrade')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CompanyPlan;
