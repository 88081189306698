export enum FeatureFlags {
  LM_TRIPS = 'TMS-1892-WEB',
  MAINTENANCE_BYPASS = 'TMS-Maintenance',
  PT_BASIC_TRIAL = 'CPUA-4769-WEB',
  FACTORING_MAINTENANCE = 'TMS-FACTORING-MAINTENANCE',
  DISPATCH = 'TMS-4499-WEB',
  SIDE_NAVIGATION = 'FUEL-4073',
  WEX_INTEGRATION_AUTOMATION = 'TMS-4211-WEB',
  ADVANCED_LOADS = 'f6345c6d57db87fda0e6fcabe4fa3f39566dde889ee35b98d2a765f951becc08',
  NEW_DEBTOR_SEARCH = '4bf6748daf88aff2adcdaedfef627572c3d380ff8f1554e6e2478cc97b8e7006',
  IS_NEW_PT_ENTERPRISE_DASHBOARD = '1a1bc6f8c0731d1b6ff7325b5b9690af78d2ba1a5d66864bb97057c96e929175',
  IS_NEW_PT_ENTERPRISE_EQUIPMENT_PARTNERS = 'c40bf24ae94eae9ad4a62248087eaa85c54e1b562e950be3c3beccd24d967498',
  DISPATCH_PREMIUM = '4f18635fa376897930f6bf845dd43aeb3718f2cc3cf85dd52c052f2ea1107cea',
  PAYOUT = 'dffe06abfcf829bfeb5f579a3702c35d4a2fec4e984fca4e62d85b62c859b5d2',
  SHARED_SCHEDURE_GRID = 'ff6785ccf08df27415a555db923e3876cd550596d25be1a21274270b1505d9a2',
}
