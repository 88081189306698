import { ErrorData } from '../../../utils/validation-utils';

export class BusinessPartnerValidationData {
  types: ErrorData;
  email: ErrorData;
  firstName: ErrorData;
  lastName: ErrorData;
  companyName: ErrorData;
  website: ErrorData;
  phoneNumber: ErrorData;
  emergencyPhoneNumber: ErrorData;
  faxNumber: ErrorData;
  postalCode: ErrorData;
  cdlNumber: ErrorData;
  cdlExpirationDate: ErrorData;
  dateOfBirth: ErrorData;
  contacts: ErrorData;
}

export const defaultPartnerValidation: BusinessPartnerValidationData = {
  types: { hasError: false, errorMessage: '' },
  email: { hasError: false, errorMessage: '' },
  firstName: { hasError: false, errorMessage: '' },
  lastName: { hasError: false, errorMessage: '' },
  companyName: { hasError: false, errorMessage: '' },
  website: { hasError: false, errorMessage: '' },
  phoneNumber: { hasError: false, errorMessage: '' },
  emergencyPhoneNumber: { hasError: false, errorMessage: '' },
  faxNumber: { hasError: false, errorMessage: '' },
  postalCode: { hasError: false, errorMessage: '' },
  cdlNumber: { hasError: false, errorMessage: '' },
  cdlExpirationDate: { hasError: false, errorMessage: '' },
  dateOfBirth: { hasError: false, errorMessage: '' },
  contacts: { hasError: false, errorMessage: '' },
};
