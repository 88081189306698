// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".request-upgrade__mainContainer___cpL2Y{display:flex;flex-direction:column;justify-content:center;align-items:center;background-color:#f9f9f9}.request-upgrade__mainContainer___cpL2Y .request-upgrade__title___3-LUi{margin-top:50px;font-weight:800;font-size:50px;line-height:60px;text-align:center;color:#000}.request-upgrade__mainContainer___cpL2Y .request-upgrade__subTitle___IIGkO{margin-top:20px;font-weight:400;font-size:24px;line-height:30px;text-align:center}.request-upgrade__mainContainer___cpL2Y .request-upgrade__plans___3vGKZ{display:flex;flex-direction:row;flex-wrap:wrap;justify-content:center;margin-top:50px}", "",{"version":3,"sources":["/codebuild/output/src2855682126/src/src/components/company-plan/styles/request-upgrade.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,wBAAA,CAEA,wEACE,eAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,UAAA,CAGF,2EACE,eAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CAGF,wEACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,sBAAA,CACA,eAAA","file":"request-upgrade.scss","sourcesContent":[".mainContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: #f9f9f9;\n\n  .title {\n    margin-top: 50px;\n    font-weight: 800;\n    font-size: 50px;\n    line-height: 60px;\n    text-align: center;\n    color: #000000;\n  }\n\n  .subTitle {\n    margin-top: 20px;\n    font-weight: 400;\n    font-size: 24px;\n    line-height: 30px;\n    text-align: center;\n  }\n\n  .plans {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: center;\n    margin-top: 50px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"mainContainer": "request-upgrade__mainContainer___cpL2Y",
	"title": "request-upgrade__title___3-LUi",
	"subTitle": "request-upgrade__subTitle___IIGkO",
	"plans": "request-upgrade__plans___3vGKZ"
};
module.exports = exports;
