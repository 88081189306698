// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".loading-spinner__loadingContainer___3ipLh{position:absolute;width:100%;height:100%;background-color:#fff;opacity:.5;z-index:1000}.loading-spinner__loadingSpinner___3SWD1{position:absolute;z-index:9999;top:calc(50% - 40px);left:calc(50% - 30px)}", "",{"version":3,"sources":["/codebuild/output/src2855682126/src/src/components/core/styles/loading-spinner.scss"],"names":[],"mappings":"AAAA,2CACI,iBAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CACA,UAAA,CACA,YAAA,CAGJ,yCACI,iBAAA,CACA,YAAA,CACA,oBAAA,CACA,qBAAA","file":"loading-spinner.scss","sourcesContent":[".loadingContainer {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    background-color: #FFFF;\n    opacity: 0.5;\n    z-index: 1000;\n}\n\n.loadingSpinner {\n    position: absolute;\n    z-index: 9999;\n    top: calc(50% - 40px);\n    left: calc(50% - 30px);\n}"]}]);
// Exports
exports.locals = {
	"loadingContainer": "loading-spinner__loadingContainer___3ipLh",
	"loadingSpinner": "loading-spinner__loadingSpinner___3SWD1"
};
module.exports = exports;
