import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPartnerName, mapPartnerToSummary, validatePartner } from '../utils/partnerUtils';
import { PartnerService } from '../services/partner.service';
import { BusinessPartner } from '../interfaces/BusinessPartner';
import { BusinessPartnerSummary } from '../interfaces/BusinessPartnerSummary';
import { BusinessPartnerValidationData, defaultPartnerValidation } from '../interfaces/BusinessPartnerValidationData';
import { SnackBarConfig } from 'common/interfaces/SnackBarConfig';
import { TFunction } from 'i18next';
import { DebtorService } from '../services/debtor.service';
import { Debtor } from 'rtspro-financial-core-module';

export interface PartnerState {
  partnersSummary: BusinessPartnerSummary[];
  currentPartner: BusinessPartner;
  partnerValidation: BusinessPartnerValidationData;
  isLoading: boolean;
  snackBarConfig: SnackBarConfig;
  openPhotoUploadDialog: boolean;
  translation: TFunction | null;
}

const initialState: PartnerState = {
  partnersSummary: [],
  currentPartner: new BusinessPartner(),
  partnerValidation: defaultPartnerValidation,
  isLoading: false,
  snackBarConfig: { display: false, message: '', type: undefined },
  openPhotoUploadDialog: false,
  translation: null,
};

const service = new PartnerService();
const debtorService = new DebtorService();

const getCreditRating = (debtors: Debtor[], mcNumber: string, name: string) => {
  // Are all credit ratings the same? The return that rating.
  const matchingItems = debtors.filter((dbtr) => dbtr.mc_number === mcNumber);
  const uniqueCreditRatings = Array.from(new Set(matchingItems.map((dbtr) => dbtr.rating)));
  if (uniqueCreditRatings.length === 1) {
    return uniqueCreditRatings[0] ?? 'NA';
  }

  // Can we find a unique credit rating based on name and mc number? Then return that rating.
  const matchingItemsFromBothNumberAndName = debtors.filter(
    (dbtr) => dbtr.mc_number === mcNumber && dbtr.name === name,
  );
  const uniqueCreditRatingsFromBothSearch = Array.from(
    new Set(matchingItemsFromBothNumberAndName.map((dbtr) => dbtr.rating)),
  );
  if (uniqueCreditRatingsFromBothSearch.length === 1) {
    return uniqueCreditRatingsFromBothSearch[0] ?? 'NA';
  }

  // Otherwise, return NA
  return 'NA';
};

const getCreditRatingForIndividualPartner = async (partner: BusinessPartner) => {
  const { mcNumber } = partner;
  const debtors = mcNumber ? await debtorService.getDebtors([mcNumber]) : [];
  return getCreditRating(debtors, mcNumber, getPartnerName(partner));
};

export const getPartnersSummary = createAsyncThunk('partner/getPartnersSummary', async () => {
  const { data } = await service.getPartnersSummary();
  if (data && data.length > 0) {
    const mcNumbers = data.map((p) => p.mcNumber);
    const debtors = await debtorService.getDebtors(mcNumbers);
    data.forEach((p) => {
      p.creditRating = getCreditRating(debtors, p.mcNumber, p.name);
    });
  }

  return data;
});

export const savePartner = createAsyncThunk('partner/savePartner', async (payload: BusinessPartner) => {
  let result = await service.savePartner(payload);
  if (payload.mcNumber) {
    const partnerCreditRating = await getCreditRatingForIndividualPartner(payload);
    result.creditRating = partnerCreditRating;
  }
  return result;
});

export const removePartner = createAsyncThunk('partner/removePartner', async (payload: number) => {
  await service.removePartner(payload);
  return payload;
});

export const updatePartnerState = createAsyncThunk('partner/updatePartnerState', async (payload: any) => {
  const { id, active } = payload;
  await service.updatePartnerState(id, active);
  return payload;
});

const sortPartnerSummary = (a: BusinessPartnerSummary, b: BusinessPartnerSummary): number => {
  return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
};

export const partnerSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setPartnersSummary: (state: PartnerState, action: PayloadAction<BusinessPartnerSummary[]>) => {
      state.partnersSummary = action.payload;
    },
    setCurrentPartner: (state: PartnerState, action: PayloadAction<BusinessPartner>) => {
      state.currentPartner = action.payload;
      state.partnerValidation = validatePartner(action.payload, state.partnerValidation, state.translation);
    },
    setPartnerValidation: (state: PartnerState, action: PayloadAction<BusinessPartnerValidationData>) => {
      state.partnerValidation = action.payload;
    },
    setTranslations: (state: PartnerState, action: PayloadAction<TFunction>) => {
      state.translation = action.payload;
    },
    setSnackbarConfig: (state: PartnerState, action: PayloadAction<SnackBarConfig>) => {
      state.snackBarConfig = action.payload;
    },
    showInfoSnackbar: (state: PartnerState, action: PayloadAction<string>) => {
      state.snackBarConfig = { display: true, message: action.payload, type: 'info' };
    },
    showSuccessSnackbar: (state: PartnerState, action: PayloadAction<string>) => {
      state.snackBarConfig = { display: true, message: action.payload, type: 'success' };
    },
    showErrorSnackbar: (state: PartnerState, action: PayloadAction<string>) => {
      state.snackBarConfig = { display: true, message: action.payload, type: 'error' };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPartnersSummary.pending, (state, _action) => {
        state.isLoading = true;
      })
      .addCase(getPartnersSummary.fulfilled, (state, action) => {
        state.partnersSummary = action.payload.sort((a: any, b: any) => sortPartnerSummary(a, b));
        state.isLoading = false;
      })
      .addCase(getPartnersSummary.rejected, (state, _action) => {
        state.isLoading = false;
        state.snackBarConfig = {
          display: true,
          message: state.translation
            ? state.translation('error_loading')
            : 'Error loading partners, please refresh the page',
          type: 'error',
        };
      })
      .addCase(savePartner.fulfilled, (state, action) => {
        const partner = action.payload;

        state.currentPartner = partner;
        state.snackBarConfig = {
          display: true,
          message: state.translation
            ? state.translation('Your information has been saved')
            : 'Your information has been saved',
          type: 'success',
        };

        const partnerSummaryIndex = state.partnersSummary.findIndex((p) => p.id === partner.id);
        if (partnerSummaryIndex === -1) {
          state.partnersSummary.push(mapPartnerToSummary(partner));
        } else {
          state.partnersSummary[partnerSummaryIndex] = mapPartnerToSummary(partner);
        }
        state.partnersSummary.sort((a, b) => sortPartnerSummary(a, b));
      })
      .addCase(savePartner.rejected, (state, _action) => {
        state.snackBarConfig = {
          display: true,
          message: state.translation ? state.translation('error_saving') : 'Error updating partner, please try again',
          type: 'error',
        };
      })
      .addCase(removePartner.fulfilled, (state, action) => {
        state.snackBarConfig = {
          display: true,
          message: state.translation ? state.translation('success_removing') : 'Partner has been removed',
          type: 'success',
        };
        state.partnersSummary = state.partnersSummary.filter((p) => {
          return p.id !== action.payload;
        });
      })
      .addCase(removePartner.rejected, (state, _action) => {
        state.snackBarConfig = {
          display: true,
          message: state.translation ? state.translation('error_removing') : 'Error removing partner, please try again',
          type: 'error',
        };
      })
      .addCase(updatePartnerState.fulfilled, (state, action) => {
        state.currentPartner.isActive = action.payload.active;
        const partner = state.partnersSummary.find((p) => p.id === action.payload.id);
        if (partner) partner.active = action.payload.active;
      })
      .addCase(updatePartnerState.rejected, (state, _action) => {
        state.snackBarConfig = {
          display: true,
          message: state.translation
            ? state.translation('error_changing_state')
            : "Error changing the partner's state, please try again",
          type: 'error',
        };
      });
  },
});

export default partnerSlice.reducer;

export const {
  setPartnersSummary,
  setCurrentPartner,
  setPartnerValidation,
  setTranslations,
  setSnackbarConfig,
  showInfoSnackbar,
  showSuccessSnackbar,
  showErrorSnackbar,
} = partnerSlice.actions;
