import Service from './service';
import { AxiosInstance, AxiosResponse } from 'axios';
import Axios from 'axios';
import {
  DocumentDeleteResponse,
  DocumentSaveResponse,
  PreSignedUrl,
  UploadMetaData,
} from '../components/core/s3-document.controller';
import Document from '../interfaces/document';
import { getCompanyId } from '../utils/authUtils';
const Config = require('Config');
const mime = require('mime');

interface UploadOptions {
  name: string;
  destinationBucket?: string;
  transactionId?: string;
  companyId?: string;
  getUrl?: boolean;
}

export default class DocumentService extends Service {
  axiosUnAuthorized: AxiosInstance;

  constructor() {
    super(Config.documentsServiceUrl);
    this.axiosUnAuthorized = Axios.create({
      baseURL: Config.documentsServiceUrl,
      headers: {},
    });
  }

  async getDocuments(documentKey: string, companyId: string): Promise<any> {
    const url = `v2/${documentKey}`;
    const response: AxiosResponse = await this.axios.get(url);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async uploadFile(fileToUpload: any, url: string, onUploadProgress: (progressEvent) => any): Promise<any> {
    const headers = {
      'Content-Type': fileToUpload.type,
    };

    const options = {
      headers: headers,
      onUploadProgress: onUploadProgress,
    };

    const response: AxiosResponse = await this.axiosUnAuthorized.put(url, fileToUpload, options);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async saveFileMetaData(
    metaData: UploadMetaData,
    onUploadProgress?: (progressEvent) => any,
  ): Promise<DocumentSaveResponse> {
    const options = {
      onUploadProgress: onUploadProgress,
    };

    const url = 'v2/';
    const response: AxiosResponse = await this.axios.post(url, metaData, options);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getPresignedUrl(
    key: string,
    name: string,
    type: string,
    isUpload: boolean,
    isStaged: boolean,
    isView?: boolean,
    bucket?: string,
    region?: string,
    getUrl?: boolean,
  ): Promise<PreSignedUrl> {
    const mimeType = mime.getType(name);
    const body: any = {
      key: key,
      type: mimeType || type,
      isUpload: isUpload,
      isStaged: isStaged,
      view: isView,
      fileName: name,
      bucket: bucket,
    };

    if (getUrl && region) {
      body.region = region;
    }

    const url = 'v2/generatepresignedurl';
    const response: AxiosResponse = await this.axios.post(url, body);

    if (response.status !== 200) {
      throw Error();
    }
    return response.data;
  }

  async deleteDocuments(metaData: UploadMetaData): Promise<DocumentDeleteResponse> {
    const url = '/v2';
    const headers = {
      'Content-Type': 'application/json',
    };

    const options = {
      headers: headers,
      data: metaData,
    };

    const response: AxiosResponse = await this.axios.delete(url, options);

    if (response.status !== 200) {
      throw Error();
    }
    return response.data;
  }

  mapFilesToDocumentRequest(
    files: Document[],
    transactionId?: string,
    companyId?: any,
    destinationBucket?: string,
  ): UploadMetaData {
    const fileData = files.map((file: Document): any => {
      return {
        _id: file._id || null,
        name: file.name,
        key: file.key,
      };
    });
    return {
      transactionId: transactionId,
      files: fileData,
      companyId: companyId,
      destination: destinationBucket,
    };
  }

  async deleteMongoDocuments(files: Document[], destinationBucket?: string): Promise<DocumentDeleteResponse> {
    const metadata = this.mapFilesToDocumentRequest(files, undefined, destinationBucket);
    return await this.deleteDocuments(metadata);
  }

  async saveDocuments(
    files: Document[],
    transactionId: string,
    destinationBucket?: string,
    progressUpload?: any,
  ): Promise<DocumentSaveResponse> {
    const companyId = await getCompanyId();
    const metadata = this.mapFilesToDocumentRequest(files, transactionId, companyId, destinationBucket);
    return await this.saveFileMetaData(metadata, progressUpload);
  }

  async downloadDocument(key: string, name: string, isStaged: boolean, bucket?: string): Promise<void> {
    const url = await this.getPresignedUrl(key, name, 'application/octet-stream', false, isStaged, true, bucket);
    window.open(url.url, '_blank');
  }

  async uploadToStage(
    key: string,
    mimeType: string,
    file: any,
    options: UploadOptions,
    progressUpload?: any,
  ): Promise<string | undefined> {
    const { name, getUrl } = options;
    let url = await this.getPresignedUrl(key, name, mimeType, true, false);
    await this.uploadFile(file, url.url, progressUpload);
    if (getUrl) {
      url = await this.getPresignedUrl(key, name, 'application/octet-stream', false, true);
      return url.url;
    } else {
      return;
    }
  }
}
