export class DispatchDriver {
  id: number | string;
  firstName?: string;
  lastName?: string;
  profileImageKey?: string;
  phoneNumber?: string;
  email?: string;
  milesLast30Days?: number;
  earningsLast30Days?: number;

  static getDriverName(driver: DispatchDriver) {
    if (!driver) {
      return '--';
    } else if (driver.firstName && driver.lastName) {
      return `${driver.firstName} ${driver.lastName}`;
    } else {
      return driver.firstName || driver.lastName || '';
    }
  }
}
