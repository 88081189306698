import axios from 'axios';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';
import { auth0Context } from '../services/service';

export const getToken: any = async () => {
  const token = window.localStorage.getItem(LocalAuth.TOKEN_KEY);
  if (token) {
    const json = JSON.parse(token);
    return json.token;
  }
  const auth0Jwt = await auth0Context.getAccessTokenSilently();
  if (auth0Jwt) return auth0Jwt;
  return null;
};

export const getDefaultConfig = async () => {
  const token = await getToken();
  return {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    },
  };
};

const get =
  <T extends unknown, Q extends unknown>(url: string) =>
  async (query?: Q) =>
    axios.get<T>(url, { ...(await getDefaultConfig()), params: query });

const post =
  <T extends unknown, B extends unknown>(url: string) =>
  async (body?: B) =>
    axios.post<T>(url, body, { ...(await getDefaultConfig()) });

const put =
  <T extends unknown, B extends unknown>(url: string) =>
  async (body?: B) =>
    axios.put<T>(url, body, { ...(await getDefaultConfig()) });

const patch =
  <T extends unknown, B extends unknown>(url: string) =>
  async (body?: B) =>
    axios.patch<T>(url, body, { ...(await getDefaultConfig()) });

const doDelete =
  <T extends unknown, Q extends unknown>(url: string) =>
  async (query?: Q) =>
    axios.delete<T>(url, { ...(await getDefaultConfig()), params: query });

const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const http = { get, put, post, patch, delete: doDelete, delay };

export default http;
