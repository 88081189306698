import { BusinessPartnerTypeEnum } from '../enums/BusinessPartnerTypeEnum';
import { BusinessPartnerContact } from './BusinessPartnerContact';
import { BusinessPartnerType } from './BusinessPartnerType';
import { Endorsement } from './Endorsement';

export class BusinessPartner {
  id: number;
  companyId: number;
  firstName: string;
  lastName: string;
  fullName: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  fax: string;
  webSite: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  profileImageKey: string;
  transactionKey: string;
  isActive: boolean;
  mcNumber: string;
  billingTermsId?: number;
  billingTerms: string;
  creditLimit?: number;
  creditRating?: string;
  contacts: BusinessPartnerContact[];
  types: BusinessPartnerType[]; // TODO:  types: BusinessPartnerTypeEnum;

  // Driver Info
  dateOfBirth?: string;
  nickname?: string;

  // CDL Info
  cdlNumber?: string;
  cdlState?: string;
  cdlExpiration?: string;
  cdlClass?: string;
  cdlEndorsements?: Endorsement[];

  // Emergency Info
  emergencyContact: string;
  emergencyPhoneNumber: string;

  constructor() {
    this.isActive = true;
    this.contacts = [new BusinessPartnerContact()];
    this.types = [
      {
        id: BusinessPartnerTypeEnum.Driver,
        name: BusinessPartnerTypeEnum[BusinessPartnerTypeEnum.Driver],
        checked: true,
        disabled: false,
      },
    ];
  }
}
